<template>
  <div v-if="document">
    <div
      class="document-preview d-flex flex-column"
    >
      <div
        class="d-flex justify-content-between w-100 flex-wrap"
      >
        <div
          class="d-flex align-items-center mw-100 my-2"
        >
          <div class="mr-1">
            <feather-icon
              icon="FileTextIcon"
              size="36"
            />
          </div>
          <div class="mw-100">
            <div class="d-flex flex-column">
              <h6 class="document-preview-type">
                {{ isReceipt ? `${docName} receipt` : docName }}
              </h6>
              <span class="text-muted">
                {{ getDate(document.invoice_creation_date || document.created_at) }}
              </span>
            </div>
          </div>
        </div>
        <div
          class="d-flex align-items-center justify-content-end"
        >
          <div
            class="document-preview-buttons"
          >
            <b-button
              class="mr-1"
              size="sm"
              @click="getPath(document)"
            >
              <feather-icon
                icon="DownloadIcon"
                size="18"
              />
            </b-button>
            <b-button
              target="_blank"
              size="sm"
              @click="getPath(document)"
            >
              <feather-icon
                icon="EyeIcon"
                size="18"
              />
            </b-button>
            <b-button
              v-if="!isDropZoneNeeded"
              size="sm"
              class="ml-1"
              @click="removeDoc"
            >
              <feather-icon
                icon="XIcon"
                size="18"
              />
            </b-button>
          </div>
          <div
            v-if="showZone && !isDropZoneNeeded"
            class="d-flex align-items-center document-preview-signed pointer-events-none"
          >
            <b-avatar
              class="mr-1"
              size="32"
              variant="light-success"
            >
              <feather-icon
                icon="CheckCircleIcon"
              />
            </b-avatar>
            <h6 class="mb-0">
              {{ $t('signed') }}
            </h6>
          </div>
        </div>
      </div>
      <drop-zone
        v-if="showZone && isDropZoneNeeded"
        :options="dropzoneOptions"
        @add-file="uploadFile"
      >
        <template #description>
          <div class="d-flex flex-column align-items-center">
            <h6 class="my-1">
              {{ $t('dropHere') }}
              <u>{{ descriptionType }}</u>
              {{ $t('orClickToUpload') }}
            </h6>
            <span>
              {{ $t('PdfJpegPngAllowed') }}
            </span>
          </div>
        </template>
      </drop-zone>
    </div>
  </div>
</template>

<script>
import { BButton, BAvatar } from 'bootstrap-vue';
import DropZone from '@/views/apps/components/DropZone.vue';
import axios from '@/libs/axios';

export default {
  components: {
    BButton,
    DropZone,
    BAvatar,
  },
  props: {
    document: {
      type: Object,
      default: () => {},
    },
    isReceipt: {
      type: Boolean,
      default: false,
    },
    showZone: {
      type: Boolean,
      default: true,
    },
    legal: {
      type: Object,
      default: null,
    },
    role: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    dropzoneOptions: {
      previewsContainer: false,
      autoProcessQueue: false,
      acceptedFiles: 'application/pdf,image/jpeg,image/png',
      url: 'localhost',
    },
  }),
  computed: {
    isDropZoneNeeded() {
      const isDataFilled = this.document.signed;
      let isSigned = this.$store.state.profile.profile?.sign_agreement_signed;
      if (this.legal) {
        isSigned = this.legal.sign_agreement_signed;
      }
      return !isDataFilled && !isSigned;
    },
    docName() {
      return this.document.name || this.document.document_name;
    },
    descriptionType() {
      if (this.document.type === 'supply_contract') {
        return this.$t('salePurchaseAgreement');
      }
      if (this.document.type === 'purchase') {
        return this.$t('invoice');
      }
      if (this.document.type === 'packing') {
        return this.$t('packing');
      }
      return this.$t('dropCheckToUpload');
    },
  },
  methods: {
    async uploadFile(files) {
      const [file] = files;
      const formData = new FormData();
      if (this.document.type === 'purchase') {
        formData.append('type', 'sale_purchase_invoice');
        if (this.legal) formData.append('legal_id', String(this.legal.company_id));
        formData.append('document', file);
        await this.$store.dispatch('deal/uploadInvoice', { formData });
      }
    },
    async removeDoc() {
      const documentId = this.document.id;
      const { deal } = this.$store.state.deal;
      const { uploaded_documents } = deal;
      const userRole = this.role || deal.user_role;
      // if (this.document.type === 'supply_contract') {
      //   documentId = uploaded_documents[userRole]?.sale_purchase_agreement?.id;
      // }
      // if (this.document.type === 'purchase') {
      //   documentId = uploaded_documents[userRole]?.sale_purchase_invoice?.id;
      // }
      // if (this.document.type === 'packing') {
      //   documentId = uploaded_documents[userRole]?.sale_purchase_invoice?.packing.id;
      // }
      await this.$store.dispatch('deal/removeDocument', { documentId });
    },
    async getPath(item) {
      let url = item?.url;
      if (!url) {
        const getUrl = await this.$store.dispatch('deal/getDocument', item.id);
        url = URL.createObjectURL(getUrl.data);
      }

      window.open(url, '_blank');
    },
    getDate(date) {
      if (date) {
        const newDate = new Date(date);
        const day = newDate.getDate() < 9 ? `0${newDate.getDate()}` : newDate.getDate();
        const month = (newDate.getMonth() + 1) <= 9 ? `0${(newDate.getMonth() + 1)}` : newDate.getMonth() + 1;
        return `${day}.${month}.${newDate.getFullYear()}`;
      }
      return '';
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";
.document-preview {
  cursor: pointer;
  overflow: hidden;
  &-buttons {
    opacity: 0;
    transition: all .4s ease;
  }
  &-signed {
    position: absolute;
    transition: all .4s ease;
    opacity: 1;
  }
  &:hover {
    .document-preview-buttons {
      opacity: 1;
    }
    .document-preview-signed {
      opacity: 0;
    }
  }
  &-type {
    font-weight: bold;
    text-transform: capitalize;
  }
}
</style>
