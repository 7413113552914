<template>
  <div>
    <b-card
      no-body
      :class="{'open': isDeliveryVisible}"
      class="collapse-icon"
    >
      <b-card-header
        :class="{'collapsed': !isDeliveryVisible}"
        :aria-expanded="isDeliveryVisible ? 'true' : 'false'"
        aria-controls="delivery-collapse"
        role="tab"
        data-toggle="collapse"
        @click="updateDeliveryVisible(!isDeliveryVisible)"
      >
        <h4 class="d-inline-block mr-1 mb-0">
          Delivery
        </h4>
      </b-card-header>
      <b-collapse
        id="delivery-collapse"
        v-model="isDeliveryVisible"
        role="tabpanel"
        accordion
      >
        <hr class="divider m-0" />
        <b-card-body>
          <h5 class="mb-1">
            Please select a delivery option
          </h5>
          <div>
            <b-row>
              <b-col
                v-for="delivery in options.delivery"
                :key="delivery.id"
                md="6"
                lg="6"
                cols="12"
              >
                <delivery-card-variant
                  :disabled="[1,2,3,4].includes(dealCurrentStatus) === false"
                  :variant="delivery"
                  :selected-variant="selectedDelivery"
                  @select="selectDelivery"
                />
              </b-col>
            </b-row>
            <b-button
              v-if="isAllowToRequestDeliveryCost"
              class="mt-2"
              :disabled="isActionDisabled"
              variant="primary"
              @click="postDelivery"
            >
              Request delivery cost
            </b-button>
            <div>
              <transactions-documents-list :documents="deliveryDocuments" />
              <drop-zone
                v-if="isAllowUploadDeliveryReceipts && !deliveryInvoice"
                class="mt-2"
                :options="dropzoneOptions"
                @add-file="addDeliveryInvoice"
              />
              <div
                v-if="deliveryInvoice"
                class="d-flex my-1 align-items-center"
              >
                <feather-icon
                  class="mr-1"
                  icon="FileTextIcon"
                  size="25"
                />
                <span class="mr-1">
                  {{ deliveryInvoice.name }}
                </span>
                <a
                  data-action="close"
                  @click="deliveryInvoice = null"
                >
                  <feather-icon
                    icon="XIcon"
                    size="14"
                  />
                </a>
              </div>
              <b-button
                v-if="deliveryInvoice"
                variant="primary"
                @click="sendDelivery"
              >
                {{ $t('deliveryPaymentSent') }}
              </b-button>
              <div v-if="isDeliveryReceiptSent">
                <div
                  v-if="!deliveryInvoice"
                  class="d-flex align-items-center"
                >
                  <b-avatar
                    size="32"
                    variant="light-success"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                    />
                  </b-avatar>
                  <h5 class="mb-0 ml-1">
                    {{ $t('deliveryPaymentSent') }}
                  </h5>
                </div>
                <transactions-documents-list
                  v-if="!deliveryInvoice"
                  :documents="deliveryDocuments"
                  :is-receipt="true"
                />
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card
      no-body
      :class="{'open': isClearanceVisible}"
      class="collapse-icon"
    >
      <b-card-header
        :class="{'collapsed': !isClearanceVisible}"
        :aria-expanded="isClearanceVisible ? 'true' : 'false'"
        aria-controls="clearance-collapse"
        role="tab"
        data-toggle="collapse"
        @click="updateClearanceVisible(!isClearanceVisible)"
      >
        <h4 class="d-inline-block mr-1 mb-0">
          {{ $t('customs') }}
        </h4>
      </b-card-header>
      <b-collapse
        id="clearance-collapse"
        v-model="isClearanceVisible"
        role="tabpanel"
        accordion
      >
        <hr class="divider m-0" />
        <b-card-body>
          <h5 class="mb-1">
            {{ $t('selectCustomOption') }}
          </h5>
          <div>
            <b-row>
              <b-col
                v-for="custom in options.customs"
                :key="custom.id"
                md="6"
                lg="6"
                cols="12"
              >
                <delivery-card-variant
                  :disabled="isDeliveryCardsDisabled"
                  :variant="custom"
                  :selected-variant="selectedCustom"
                  @select="selectCustom"
                />
              </b-col>
            </b-row>
            <b-button
              v-if="isAllowToRequestClearanceCost"
              class="mt-2"
              variant="primary"
              :disabled="isActionDisabled"
              @click="postCustoms"
            >
              {{ $t('requestClearanceCost') }}
            </b-button>
            <div>
              <transactions-documents-list :documents="clearanceDocuments" />
              <drop-zone
                v-if="isAllowUploadClearanceReceipts && !clearanceInvoice"
                class="mt-2"
                :options="dropzoneOptions"
                @add-file="addClearanceInvoice"
              />
              <div
                v-if="clearanceInvoice"
                class="d-flex my-1 align-items-center"
              >
                <feather-icon
                  class="mr-1"
                  icon="FileTextIcon"
                  size="25"
                />
                <span class="mr-1">
                  {{ clearanceInvoice.name }}
                </span>
                <a
                  data-action="close"
                  @click="clearanceInvoice = null"
                >
                  <feather-icon
                    icon="XIcon"
                    size="14"
                  />
                </a>
              </div>
              <b-button
                v-if="clearanceInvoice"
                variant="primary"
                @click="sendClearance"
              >
                {{ $t('customsPaymentSent') }}
              </b-button>
              <div v-if="isClearanceReceiptSent">
                <div
                  v-if="!clearanceInvoice"
                  class="d-flex align-items-center"
                >
                  <b-avatar
                    size="32"
                    variant="light-success"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                    />
                  </b-avatar>
                  <h5 class="mb-0 ml-1">
                    {{ $t('customsPaymentSent') }}
                  </h5>
                </div>
                <transactions-documents-list
                  v-if="!clearanceInvoice"
                  :documents="clearanceDocuments"
                  :is-receipt="true"
                />
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import {
  BCardBody, BRow, BCol, BButton, BAvatar, BCardHeader, BCollapse, BCard,
} from 'bootstrap-vue';
import TransactionsDocumentsList from '@/views/trading/trading-deals/transactions/TransactionsDocumentsList.vue';
import DeliveryCardVariant from '@/views/trading/trading-deals/delivery/DeliveryCardVariant.vue';
import DropZone from '@/views/apps/components/DropZone.vue';

export default {
  components: {
    BCardBody,
    BRow,
    BCol,
    DeliveryCardVariant,
    DropZone,
    TransactionsDocumentsList,
    BButton,
    BAvatar,
    BCardHeader,
    BCollapse,
    BCard,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isDeliveryInvoiceReady: false,
      isCustomInvoiceReady: false,
      delivery_cost: null,
      dropzoneOptions: {
        previewsContainer: false,
        autoProcessQueue: false,
        url: 'localhost',
      },
      options: {
        delivery: [
          { icon: 'ShoppingCartIcon', name: 'Pickup from the warehouse', id: 2 },
          { icon: 'TruckIcon', name: 'I need delivery services', id: 1 },
        ],
        customs: [
          { icon: 'BriefcaseIcon', name: 'My company will clear the goods itself', id: 2 },
          { icon: 'GiftIcon', name: 'I need Customs clearance services', id: 1 },
        ],
      },
      selectedDelivery: null,
      selectedCustom: null,
      deliveryInvoice: null,
      clearanceInvoice: null,
      isDeliveryVisible: true,
      isClearanceVisible: true,
    };
  },
  computed: {
    isDeliveryCardsDisabled() {
      return [1, 2, 3, 4, 5].includes(this.dealCurrentStatus) === false || this.selectedDelivery === 2;
    },
    currentRoute() {
      const { id } = this.$route.params;
      return id || '';
    },
    isActionDisabled() {
      return !Object.values(this.deal.uploaded_documents).some(value => !!value);
    },
    deal() {
      return this.$store.state.deal.deal;
    },
    legal() {
      return this.deal.legal;
    },
    deliveryDocuments() {
      return this.legal.invoices.filter(item => item.type === 'delivery');
    },
    clearanceDocuments() {
      return this.legal.invoices.filter(item => item.type === 'clearance');
    },
    dealCurrentStatus() {
      return this.$store.state.deal.deal.status;
    },
    isAllowToRequestDeliveryCost() {
      return this.selectedDelivery === 1 && !this.legal.invoices.some(item => item.type === 'delivery');
    },
    isAllowToRequestClearanceCost() {
      return this.selectedCustom === 1 && !this.legal.invoices.some(item => item.type === 'clearance');
    },
    isDeliveryReceiptSent() {
      return this.legal.invoices.find(item => item.type === 'delivery')?.receipt_sent || false;
    },
    isClearanceReceiptSent() {
      return this.legal.invoices.find(item => item.type === 'clearance')?.receipt_sent || false;
    },
    isAllowUploadDeliveryReceipts() {
      return [1, 2, 3, 4].includes(this.dealCurrentStatus)
          && this.legal.invoices.some(item => item.type === 'delivery')
          && !this.isDeliveryReceiptSent;
    },
    isAllowUploadClearanceReceipts() {
      return [1, 2, 3, 4, 5].includes(this.dealCurrentStatus)
          && this.legal.invoices.some(item => item.type === 'clearance')
          && !this.isClearanceReceiptSent;
    },
  },
  watch: {
    dealCurrentStatus() {
      this.selectedDelivery = this.$store.state.deal.deal.delivery_type;
    },
    deal: {
      immediate: true,
      handler(val) {
        this.selectedDelivery = val.delivery_type;
        this.selectedCustom = val.clearance_type;
      },
    },
    isDeliveryReceiptSent: {
      immediate: true,
      handler(value) {
        if (value) {
          setTimeout(() => this.updateDeliveryVisible(false), 500);
        }
      },
    },
    isClearanceReceiptSent: {
      immediate: true,
      handler(value) {
        if (value) {
          setTimeout(() => this.updateClearanceVisible(false), 500);
        }
      },
    },
  },
  methods: {
    updateDeliveryVisible(val = true) {
      this.isDeliveryVisible = val;
    },
    updateClearanceVisible(val = true) {
      this.isClearanceVisible = val;
    },
    addDeliveryInvoice(files) {
      const [file] = files;
      this.deliveryInvoice = file;
    },
    addClearanceInvoice(files) {
      const [file] = files;
      this.clearanceInvoice = file;
    },
    async sendDelivery() {
      const form = new FormData();
      form.set('receipt', this.deliveryInvoice);
      form.set('stage', 'delivery');
      this.deliveryInvoice = null;
      await this.$store.dispatch('deal/setPaymentSent', { payload: form, id: this.currentRoute });
    },
    async sendClearance() {
      const form = new FormData();
      form.set('receipt', this.clearanceInvoice);
      form.set('stage', 'clearance');
      this.clearanceInvoice = null;
      await this.$store.dispatch('deal/setPaymentSent', { payload: form, id: this.currentRoute });
    },
    async selectDelivery(deliveryId) {
      this.selectedDelivery = deliveryId;
      await this.postDelivery();
      if (this.selectedDelivery === 2) {
        await this.selectCustom(this.selectedDelivery);
      }
    },
    async postDelivery() {
      await this.postVariant(this.selectedDelivery, 'delivery', 'delivery_type');
    },
    async postCustoms() {
      await this.postVariant(this.selectedCustom, 'clearance', 'clearance_type');
    },
    async selectCustom(customId) {
      this.selectedCustom = customId;
      await this.postCustoms();
    },
    async postVariant(id, type, paramName) {
      try {
        const params = new URLSearchParams();
        params.append(paramName, id);
        await this.$store.dispatch('deal/setDeliveryOptions', { payload: params, id: this.currentRoute, type });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
