<template>
  <b-card
    no-body
    :class="{'open': visible}"
    class="collapse-icon"
  >
    <b-card-header
      :class="{'collapsed': !visible}"
      :aria-expanded="visible ? 'true' : 'false'"
      aria-controls="products-collapse"
      role="tab"
      data-toggle="collapse"
      @click="updateVisible(!visible)"
    >
      <h4 class="d-inline-block mr-1 mb-0">
        {{ title }}
      </h4>
    </b-card-header>
    <b-collapse
      id="products-collapse"
      v-model="visible"
      role="tabpanel"
      accordion
    >
      <hr class="divider m-0" />
      <b-card-body>
        <b-table
          ref="refDealProducts"
          class="position-relative product-table"
          primary-key="id"
          :empty-text="$t('noMatchingText')"
          responsive
          outlined
          show-empty
          :items="items"
          :fields="fields"
          @row-clicked="rowClick"
        >
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="columnStyle(field)"
            />
          </template>
          <template #cell(item)="data">
            <div class="d-flex flex-column">
              <b class="product-name">
                {{ data.item.name }}
              </b>
              <small class="text-muted">
                {{ data.item.index }}
              </small>
            </div>
          </template>

          <template #cell(vat)="data">
            <span v-if="data.item.vat">
              {{ data.item.vat }}%
            </span>
            <span v-else>
              -
            </span>
          </template>

          <template #cell(cost)="data">
            <span>
              {{ data.item.total_sum.toFixed(2) }}
            </span>
          </template>
          <template #cell(tnv)="data">
            <span>
              {{ data.item.tnv }}
            </span>
          </template>

          <template #row-details="data">
            <b-card no-body>
              <b-row v-if="productsToEdit.includes(data.item.id)">
                <b-col
                  cols="2"
                  class="flex-wrap"
                >
                  <template v-for="element in getImagesAndCertificates(data.item)">
                    <b-img
                      v-if="element.type !== 'certificate'"
                      :key="element.id"
                      :src="element.url"
                      class="mr-1"
                      width="62px"
                      rounded
                      @click="selectImage(data.item, element)"
                    />
                    <div
                      v-else
                      :key="element.id"
                      class="product-new-image-wrapper"
                    >
                      <feather-icon
                        size="22"
                        icon="EyeIcon"
                        class="cursor-pointer product-new-image-show"
                        @click="openDocument(element)"
                      />
                      <b-img
                        class="product-new-image"
                        :src="getDocumentImageUrl(element)"
                        width="60px"
                        height="40px"
                        rounded
                      />
                    </div>
                  </template>
                </b-col>
                <b-col
                  cols="2"
                >
                  <b>{{ $t('packing') }}</b>
                </b-col>
                <b-col cols="8">
                  <div class="d-flex align-items-center justify-content-between">
                    <trading-new-packing
                      :deal-packing="true"
                      :product="data.item"
                      @update="updatePacking(data.item, $event)"
                    />
                    <b-button
                      class="ml-2"
                      variant="primary"
                      @click="savePacking(data.item)"
                    >
                      {{ $t('save') }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col
                  cols="3"
                  class="flex-wrap"
                >
                  <template v-for="element in getImagesAndCertificates(data.item)">
                    <b-img
                      v-if="element.type !== 'certificate'"
                      :key="element.id"
                      :src="element.url"
                      class="mr-1"
                      width="62px"
                      rounded
                      @click="selectImage(data.item, element)"
                    />
                    <div
                      v-else
                      :key="element.id"
                      class="product-new-image-wrapper"
                    >
                      <feather-icon
                        size="22"
                        icon="EyeIcon"
                        class="cursor-pointer product-new-image-show"
                        @click="openDocument(element)"
                      />
                      <b-img
                        class="product-new-image"
                        :src="getDocumentImageUrl(element)"
                        width="60px"
                        height="40px"
                        rounded
                      />
                    </div>
                  </template>
                </b-col>
                <b-col
                  cols="2"
                  class="pl-3"
                >
                  <b>{{ $t('packing') }}</b>
                </b-col>
                <b-col
                  cols="2"
                >
                  <div class="d-flex flex-column">
                    <span>{{ $t('qty') }} (@10):</span>
                    <span>{{ $t('netWeight') }}:</span>
                  </div>
                </b-col>
                <b-col
                  cols="1 pl-0"
                >
                  <div class="d-flex flex-column">
                    <span>{{ data.item.packing_qty || 0 }} {{ $t('pcs') }}</span>
                    <span>{{ data.item.packing_net || 0 }} {{ $t('kgs') }}</span>
                  </div>
                </b-col>
                <b-col
                  cols="2"
                >
                  <div class="d-flex flex-column">
                    <span>{{ $t('grossWeight') }}:</span>
                    <span>Meas' Mt:</span>
                  </div>
                </b-col>
                <b-col
                  cols="1 pl-0"
                >
                  <div class="d-flex flex-column">
                    <span>{{ data.item.packing_gross || 0 }} {{ $t('kgs') }}</span>
                    <span>{{ data.item.packing_meas || 0 }} {{ $t('cuFt') }}</span>
                  </div>
                </b-col>
                <b-col
                  cols="1"
                  class="d-flex justify-content-center align-items-center"
                >
                  <feather-icon
                    v-if="isEditPackingAllowed"
                    icon="EditIcon"
                    class="cursor-pointer"
                    size="15"
                    @click="updateEditablePacking(data.item.id)"
                  />
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
        <div class="d-flex justify-content-between align-items-center">
          <b-pagination
            class="mb-0"
            hide-goto-end-buttons
          />
          <div
            v-if="isEditPackingAllowed"
            class="d-flex justify-content-between align-items-center"
          >
            <div
              v-if="userType === 'admin'"
              class="mr-1"
            >
              <b-button
                v-if="!packingReady"
                variant="primary"
                @click="generatePacking"
              >
                {{ $t('generatePacking') }}
              </b-button>
              <div
                v-else
                class="d-flex align-items-center"
              >
                <b-avatar
                  class="mr-1"
                  size="32"
                  variant="light-success"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                  />
                </b-avatar>
                <h6 class="mb-0">
                  {{ $t('packingIsReady') }}
                </h6>
              </div>
            </div>
            <b-form-checkbox
              id="editProducts"
              v-model="editToggle"
              switch
              @change="onEditToggleChange"
            />
            <label
              for="editProducts"
              class="mb-0"
            >
              {{ $t('editPacking') }}
            </label>
          </div>
        </div>
      </b-card-body>
    </b-collapse>
    <b-modal
      v-if="selectedImage"
      id="modal-center"
      v-model="imagePopup"
      :hide-footer="true"
      size="lg"
      centered
      :title="selectedImage.name"
    >
      <div>
        <div class="d-flex justify-content-between image-popup-controls">
          <b-button
            size="sm"
            @click="showPrev"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              size="18"
            />
          </b-button>
          <b-button
            size="sm"
            @click="showNext"
          >
            <feather-icon
              icon="ArrowRightIcon"
              size="18"
            />
          </b-button>
        </div>
        <b-img
          class="w-100"
          :src="selectedImage.url"
        />
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BTable, BRow, BCol, BPagination, BCollapse, BImg, BModal, BButton, BFormCheckbox, BAvatar,
} from 'bootstrap-vue';
import TradingNewPacking from '@/views/trading/trading-add-item/offers/TradingNewPacking';
import { getCurrencySymbol } from '@/constants/currency.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import axios from '@/libs/axios';

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BTable,
    BRow,
    BCol,
    BPagination,
    BCollapse,
    BModal,
    BImg,
    BButton,
    BFormCheckbox,
    TradingNewPacking,
    BAvatar,
  },
  props: {
    title: {},
    currency: {
      type: Number,
      default: 0,
    },
    userType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visible: true,
      items: [],
      imagePopup: false,
      selectedImage: null,
      selectedProduct: null,
      editToggle: false,
      productsToEdit: [],
    };
  },
  computed: {
    isEditPackingAllowed() {
      const allowedUserType = this.userType === 'admin' || this.userType === 'supplier';
      const statusesToShow = [1, 2, 3];
      return allowedUserType && statusesToShow.includes(this.$store.state.deal.deal.status);
    },
    packingReady() {
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].packing_qty !== +this.items[i].packing_qty
            || this.products[i].packing_net !== +this.items[i].packing_net
            || this.products[i].packing_gross !== +this.items[i].packing_gross
            || this.products[i].packing_meas !== +this.items[i].packing_meas) {
          return false;
        }
      }
      return true;
    },
    fields() {
      return [
        { key: 'item', label: this.$t('item'), sortable: true },
        { key: 'count', label: this.$t('qty'), sortable: true },
        { key: 'price', label: `${this.$t('price')}, ${getCurrencySymbol(this.currency)}`, sortable: true },
        { key: 'vat', label: this.$t('vat'), sortable: true },
        { key: 'cost', label: `${this.$t('cost')}, ${getCurrencySymbol(this.currency)}`, sortable: true },
        { key: 'tnv', label: this.$t('tnVed'), sortable: true },
        { key: 'actions', label: '' },
      ];
    },
    products() {
      return this.$store.state?.deal?.deal?.products || [];
    },
  },
  watch: {
    products: {
      handler(value) {
        this.items = value.map(item => ({ ...item, _showDetails: false }));
      },
      immediate: true,
    },
  },
  created() {
    if (this.userType === 'admin') {
      const statusesToShow = [7, 8];
      const { deal } = this.$store.state.deal;
      if (!statusesToShow.includes(deal.status)) {
        this.updateVisible(false);
      }
    }
  },
  methods: {
    onEditToggleChange(value) {
      this.productsToEdit = value ? this.items.map(item => item.id) : [];
    },
    updateEditablePacking(id) {
      this.productsToEdit.push(id);
      if (!this.editToggle) this.editToggle = true;
    },
    removeEditablePacking(id) {
      const index = this.productsToEdit.indexOf(id);
      if (index > -1) {
        this.productsToEdit.splice(index, 1);
      }
      if (!this.productsToEdit.length) this.editToggle = false;
    },
    rowClick(_, index) {
      if (this.items[index]._showDetails) {
        this.removeEditablePacking(this.items[index].id);
      }
      this.items[index]._showDetails = !this.items[index]._showDetails;
    },
    async generatePacking() {
      const { data } = await this.$http.post(`/offers/legal-members/${this.$store.state?.deal?.deal.offer.id}/generate-packing`);
      if (data.status) {
        const dealData = await axios.get(`/deals/${this.$store.state?.deal?.deal.id}/show`);

        this.$store.commit('deal/SET_DEAL', dealData.data.data.deal);
      }
    },
    getImagesAndCertificates(item) {
      return [...item.images, ...item.documents] || [];
    },
    updatePacking(product, packing) {
      Object.assign(this.items.find(item => item.id === product.id), packing);
    },
    async savePacking(product) {
      const { _showDetails: _, ...updatedProduct } = product;
      this.removeEditablePacking(product.id);
      const { data } = await this.$http.put(`/products/update/${updatedProduct.id}`, updatedProduct);
      if (data.status) {
        this.setSystemNotification(this.$t('changesSaved'), 'CheckCircleIcon', 'success');
      }
    },
    setSystemNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      });
    },
    selectImage(item, image) {
      this.selectedImage = image;
      this.selectedProduct = item;
      this.imagePopup = true;
    },
    showPrev() {
      const images = this.getImagesAndCertificates(this.selectedProduct);
      const imageId = images.findIndex(item => item.id === this.selectedImage.id) - 1;
      if (images[imageId]) {
        this.selectedImage = images[imageId];
      }
    },
    showNext() {
      const images = this.getImagesAndCertificates(this.selectedProduct);
      const imageId = images.findIndex(item => item.id === this.selectedImage.id) + 1;
      if (images[imageId]) {
        this.selectedImage = images[imageId];
      }
    },
    updateVisible(val = true) {
      this.visible = val;
    },
    columnStyle(field) {
      if (field.key === 'item') {
        return { width: '35%' };
      }
      if (field.key === 'count' || field.key === 'price') {
        return { width: '10%' };
      }
      if (field.key === 'cost') {
        return { width: '15%' };
      }
      if (field.key === 'tnved') {
        return { width: '25%' };
      }
      if (field.key === 'actions') {
        return { width: '5%' };
      }
      return {};
    },
    getDocumentImageUrl(document) {
      if (document.url) {
        if (document.url.match('(png|jpg|jpeg)')) {
          return document.url;
        }

        if (document.url.match('pdf')) {
          return require('@/assets/images/placeholders/pdf-placeholder.svg');
        }
      }

      return require('@/assets/images/placeholders/document-placeholder.svg');
    },
    openDocument(document) {
      window.open(document.url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.product-table {
  min-height: 110px;
}
.image-popup-controls {
  position: absolute;
  top: calc(50% - 15px);
  padding: 0 10px;
  width: calc(100% - 40px);
}
</style>
<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";

.b-table-details, .b-table-details .row {
  background-color: $table-head-bg;
}
.dark-layout {
  .b-table-details, .b-table-details .row {
    background-color: $theme-dark-table-header-bg;
  }
}
.product-table {
  .product-name {
    transition: all .2s ease;
  }
  .b-table-has-details {
    .product-name {
      color: $primary;
    }
  }
}
</style>

<style scoped lang="scss">
.product-new-image-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: .5rem;
  width: 50px;
  .product-new-image-close {
    color: #b10d0d;
    opacity: 0;
    transition: all .4s ease;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
  .product-new-image-show {
    color: #6559f2;
    opacity: 0;
    transition: all .4s ease;
    position: absolute;
    z-index: 1;
  }
  .product-new-image {
    position: relative;
    user-select: none;
  }
  &:hover {
    .product-new-image-close, .product-new-image-show {
      opacity: 1;
      z-index: 100;
    }

    .product-new-image {
      opacity: 0.6;
    }
  }
}
</style>
