<template>
  <b-card-body>
    <div>
      <h5 class="my-1">
        {{ $t('documents') }}
      </h5>
      <transactions-document-sign
        v-for="(item, index) in documents"
        :key="index"
        :document="item"
      />
    </div>
    <deal-other-docs
      document-type="transaction"
      class="mt-2 p-0"
    />
    <div v-if="deal.status === 2 && purchaseInvoice && purchaseInvoice.receipt_sent">
      <h5 class="my-2">
        {{ $t('payments') }}
      </h5>
    </div>
    <div
      v-if="purchaseInvoice && purchaseInvoice.receipt_sent"
      class="d-flex justify-content-between"
    >
      <div class="d-flex align-items-center">
        <b-avatar
          size="32"
          variant="light-success"
        >
          <feather-icon
            icon="CheckCircleIcon"
          />
        </b-avatar>
        <h5 class="mb-0 ml-1">
          {{ $t('paymentSent') }}
        </h5>
      </div>
      <div v-if="deal.status === 2">
        <b-button
          v-if="showReceivedBtn"
          class="mt-1"
          variant="primary"
          @click="paymentReceived"
        >
          {{ $t('paymentReceived') }}
        </b-button>
      </div>
      <div v-if="deal.status === 3">
        <div
          class="d-flex align-items-center"
        >
          <b-avatar
            size="32"
            variant="light-success"
          >
            <feather-icon
              icon="CheckCircleIcon"
            />
          </b-avatar>
          <h5 class="mb-0 ml-1">
            {{ $t('paymentReceived') }}
          </h5>
        </div>
      </div>
    </div>

<!--    <transactions-documents-list-->
<!--      :documents="[purchaseInvoice]"-->
<!--      :is-receipt="true"-->
<!--    />-->
  </b-card-body>
</template>

<script>
import {
  BCardBody, BButton, BAvatar,
} from 'bootstrap-vue';
// import TransactionsDocumentsList from '@/views/trading/trading-deals/transactions/TransactionsDocumentsList.vue';
import TransactionsDocumentSign from '@/views/trading/trading-deals/transactions/TransactionsDocumentSign.vue';
import DealOtherDocs from '@/views/trading/trading-deals/DealOtherDocs.vue';

export default {
  components: {
    DealOtherDocs,
    BCardBody,
    BButton,
    BAvatar,
    // TransactionsDocumentsList,
    TransactionsDocumentSign,
  },
  props: {
    transactions: {
      type: Array,
      default: () => [],
    },
    deal: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    hoveredDocument: null,
    margin: null,
    paramsToAppend: [{ key: 'stage', value: 'purchase' }],
  }),
  computed: {
    purchaseInvoice() {
      return this.deal.invoices.find(item => item.type === 'purchase');
    },
    documents() {
      return [...this.deal.legal.documents, this.purchaseInvoice];
    },
    showReceivedBtn() {
      return (this.purchaseInvoice && this.purchaseInvoice.receipt_sent) || this.deal.payment_sent;
    },
  },
  methods: {
    paymentReceived() {
      this.$store.dispatch('deal/setPaymentReceived', { id: this.$route.params.id, payload: { stage: 'purchase' } });
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";
</style>
