<template>
  <b-card-body class="pt-0">
    <transactions-documents-list
      :documents="documents"
    />
    <b-button
      variant="flat-primary"
      @click="showZone = !showZone"
    >
      <span class="d-none d-sm-inline">
        {{ $t('attachOtherDocuments') }}
      </span>
    </b-button>
    <drop-zone
      v-if="showZone"
      class="mt-2"
      :options="dropzoneOptions"
      :show-documents="false"
      @add-file="uploadFile"
    >
      <template #description>
        <div class="d-flex flex-column align-items-center">
          <h6 class="my-1">
            {{ $t('dropDocumentsToUpload') }}
          </h6>
          <span>
            {{ $t('allDocFormatsAllowed') }}
          </span>
        </div>
      </template>
    </drop-zone>
  </b-card-body>
</template>

<script>
import {
  BButton, BCardBody,
} from 'bootstrap-vue';
import DropZone from '@/views/apps/components/DropZone.vue';
import TransactionsDocumentsList from '@/views/trading/trading-deals/transactions/TransactionsDocumentsList.vue';

export default {
  components: {
    TransactionsDocumentsList,
    DropZone,
    BButton,
    BCardBody,
  },
  props: {
    legal: {
      type: Object,
      default: () => {},
    },
    documentType: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    showZone: false,
    dropzoneOptions: {
      previewsContainer: false,
      autoProcessQueue: false,
      url: 'localhost',
    },
  }),
  computed: {
    legalId() {
      // if (this.legal) return String(this.legal.id);
      return String(this.$store.state.profile.profile.company.id);
    },
    documents() {
      const { deal } = this.$store.state.deal;
      const role = deal.user_role;
      let documents = [];
      if (role !== 'admin') {
        documents = deal.uploaded_documents[role]?.other;
      }
      documents = deal.uploaded_documents[role];
      const mapper = doc => doc && doc.meta === this.documentType;
      if (!documents) return [];
      return Array.isArray(documents)
        ? documents.filter(mapper)
        : Object.values(documents).filter(mapper);
    },
  },
  methods: {
    async uploadFile(files) {
      const [file] = files;
      const formData = new FormData();
      formData.append('type', 'other');
      formData.append('meta', this.documentType);

      const role = this.$store.state.deal.deal.user_role;
      if (role === 'admin') formData.append('link_id', String(this.legalId));

      formData.append('file', file);
      await this.$store.dispatch('deal/uploadDocument', { formData });
      this.showZone = false;
    },
  },
};
</script>

<style lang="scss">
</style>
