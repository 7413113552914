<template>
  <b-card-body>
    <!--   deal.status >= 2 ???   -->
    <div v-if="!!documents.length">
      <h5 class="my-1">
        {{ $t('documents') }}
      </h5>
      <transactions-document-sign
        v-for="(item, index) in documents.filter(doc => doc.type !== 'packing')"
        :key="index"
        :document="item"
      />
      <transactions-document-sign
        :document="documents.find(doc => doc.type === 'packing')"
        :show-zone="false"
      />
      <deal-other-docs
        document-type="transaction"
        class="mt-2 p-0"
      />
    </div>
    <h5 v-else>
      {{ $t('documentsWillHereSoon') }}
    </h5>
    <div v-if="deal.status === 2">
      <h5 class="my-2">
        {{ $t('payments') }}
      </h5>
      <drop-zone
        v-if="!invoiceFile && !purchaseInvoice.receipt_sent"
        :options="dropzoneOptions"
        @add-file="addInvoice"
      />
      <div
        v-if="invoiceFile && !purchaseInvoice.receipt_sent"
        class="d-flex my-1 align-items-center"
      >
        <feather-icon
          class="mr-1"
          icon="FileTextIcon"
          size="25"
        />
        <span class="mr-1">
          {{ invoiceFile.name }}
        </span>
        <a
          data-action="close"
          @click="invoiceFile = null"
        >
          <feather-icon
            icon="XIcon"
            size="14"
          />
        </a>
      </div>
      <b-button
        v-if="!purchaseInvoice.receipt_sent"
        class="mt-1"
        variant="primary"
        :disabled="!invoiceFile"
        @click="sendPayment"
      >
        {{ $t('paymentSent') }}
      </b-button>
      <div
        v-else
        class="d-flex align-items-center pt-1"
      >
        <b-avatar
          size="32"
          variant="light-success"
        >
          <feather-icon
            icon="CheckCircleIcon"
          />
        </b-avatar>
        <h5 class="mb-0 ml-1">
          {{ $t('paymentSent') }}
        </h5>
      </div>
      <transactions-documents-list
        :documents="[purchaseInvoice]"
        :is-receipt="true"
      />
    </div>
  </b-card-body>
</template>

<script>
import {
  BCardBody, BButton, BAvatar,
} from 'bootstrap-vue';
import DropZone from '@/views/apps/components/DropZone.vue';
import TransactionsDocumentsList from '@/views/trading/trading-deals/transactions/TransactionsDocumentsList.vue';
import TransactionsDocumentSign from '@/views/trading/trading-deals/transactions/TransactionsDocumentSign.vue';
import DealOtherDocs from '@/views/trading/trading-deals/DealOtherDocs.vue';

export default {
  components: {
    DealOtherDocs,
    BAvatar,
    BCardBody,
    BButton,
    DropZone,
    TransactionsDocumentSign,
    TransactionsDocumentsList,
  },
  props: {
    transactions: {
      type: Array,
      default: () => [],
    },
    deal: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    hoveredDocument: null,
    margin: null,
    invoiceFile: null,
    dropzoneOptions: {
      previewsContainer: false,
      autoProcessQueue: false,
      url: 'localhost',
    },
  }),
  computed: {
    documents() {
      return [...this.deal.legal.documents, this.purchaseInvoice];
    },
    purchaseInvoice() {
      return this.deal.legal.invoices.find(item => item.type === 'purchase');
    },
    isAllDocumentsSigned() {
      return Object.values(this.deal.uploaded_documents).some(value => !!value);
    },
  },
  methods: {
    addInvoice(files) {
      const [file] = files;
      this.invoiceFile = file;
    },
    sendPayment() {
      const formData = new FormData();
      formData.append('receipt', this.invoiceFile);
      formData.append('stage', 'purchase');
      this.$store.dispatch('deal/setPaymentSent', { payload: formData, id: this.$route.params.id });
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";
</style>
