<template>
  <b-card>
    <b-tabs
      v-if="transactions.length"
      pills
      align="center"
      @input="invoiceFile = null"
    >
      <b-tab
        v-for="(transaction, index) in preparedTransactions"
        :key="transaction.id"
        :active="transaction.first"
        title-item-class="tab-title"
      >
        <template #title>
          <span class="tab-title-text">
            {{ index + 1 }}
          </span>
        </template>
        <div class="tab-content p-1">
          <b-row class="align-items-center justify-content-center w-100">
            <b-col
              cols="12"
              lg="5"
            >
              <div class="d-flex align-items-center justify-content-center p-2 w-100">
                <b-avatar
                  size="40"
                  variant="light-primary"
                  class="mr-2"
                  :text="transaction.buyer_legal.company_name | avatarText"
                />
                <div>
                  <h6>{{ transaction.buyer_legal.company_name }}</h6>
                  <span class="company-card-country">
                    {{ $t(transaction.buyerRole) }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col
              cols="12"
              lg="1"
              class="d-flex align-items-center justify-content-center"
            >
              <feather-icon
                class="d-none d-lg-block"
                icon="ChevronRightIcon"
                size="32"
              />
              <feather-icon
                class="d-lg-none"
                icon="ChevronDownIcon"
                size="32"
              />
            </b-col>
            <b-col
              v-if="transaction.seller_legal"
              cols="12"
              lg="5"
            >
              <div class="d-flex align-items-center justify-content-center p-2">
                <b-avatar
                  size="40"
                  variant="light-primary"
                  class="mr-2"
                  :text="transaction.seller_legal.company_name | avatarText"
                />
                <div>
                  <h6>{{ transaction.seller_legal.company_name }}</h6>
                  <span class="company-card-country">
                    {{ $t(transaction.supplierRole) }}
                  </span>
                </div>
              </div>
            </b-col>
          </b-row>
          <div v-if="getDocumentsByLegalId(transaction.id).length">
            <h5 class="my-1">
              {{ getDocumentsTranslate(transaction.price ,deal.offer.currency) }}
            </h5>
            <div v-if="getRole(transaction)">
              <transactions-document-sign
                v-for="doc in getDocumentsByLegalId(transaction.id)"
                :key="doc.id"
                :role="getRole(transaction)"
                :legal="getTransactionsLegal(transaction)"
                :show-zone="showZoneForDoc(doc, index)"
                :document="doc"
              />
            </div>
            <transactions-documents-list
              v-else
              :documents="getDocumentsByLegalId(transaction.id)"
            />
          </div>
          <deal-other-docs
            :legal="getLegalMemberForOtherDocs(transaction)"
            document-type="transaction"
            class="mt-2 p-0"
          />

          <div v-if="[2,3,4].includes(deal.status) && !isCurrentInvoiceSent(transaction.id)">
            <h5 class="my-2">
              {{ $t('payments') }}
            </h5>
            <drop-zone
              v-if="!invoiceFile"
              :options="dropzoneOptions"
              @add-file="addInvoice"
            />
            <div
              v-if="invoiceFile"
              class="d-flex my-1 align-items-center"
            >
              <feather-icon
                class="mr-1"
                icon="FileTextIcon"
                size="25"
              />
              <span class="mr-1">
                {{ invoiceFile.name }}
              </span>
              <a
                data-action="close"
                @click="invoiceFile = null"
              >
                <feather-icon
                  icon="XIcon"
                  size="14"
                />
              </a>
            </div>
            <b-button
              v-if="!isCurrentInvoiceSent(transaction.id)"
              class="mt-1"
              variant="primary"
              :disabled="!invoiceFile"
              @click="sendPayment(transaction.id)"
            >
              {{ $t('paymentSent') }}
            </b-button>
          </div>
          <div
            v-if="isCurrentInvoiceSent(transaction.id)"
            class="pt-1 d-flex justify-content-between"
          >
            <div class="d-flex align-items-center">
              <b-avatar
                size="32"
                variant="light-success"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                />
              </b-avatar>
              <h5 class="mb-0 ml-1">
                {{ $t('paymentSent') }}
              </h5>
            </div>
            <div>
              <b-button
                v-if="!isCurrentInvoicePaid(transaction.id)"
                variant="primary"
                @click="paymentReceived(transaction.id)"
              >
                {{ $t('paymentReceived') }}
              </b-button>
              <div
                v-else
                class="d-flex align-items-center"
              >
                <b-avatar
                  size="32"
                  variant="light-success"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                  />
                </b-avatar>
                <h5 class="mb-0 ml-1">
                  {{ $t('paymentReceived') }}
                </h5>
              </div>
            </div>
          </div>
          <transactions-documents-list
            :documents="getReceiptsByLegalId(transaction.id)"
            :is-receipt="true"
          />
        </div>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BCard, BTab, BTabs, BAvatar, BRow, BCol, BButton,
} from 'bootstrap-vue';
import DropZone from '@/views/apps/components/DropZone.vue';
import TransactionsDocumentsList from '@/views/trading/trading-deals/transactions/TransactionsDocumentsList.vue';
import TransactionsDocumentSign from '@/views/trading/trading-deals/transactions/TransactionsDocumentSign.vue';
import DealOtherDocs from '@/views/trading/trading-deals/DealOtherDocs.vue';
import { getCurrencySymbol } from '@/constants/currency';

export default {
  components: {
    DealOtherDocs,
    BCard,
    BTab,
    BTabs,
    BAvatar,
    BRow,
    BCol,
    BButton,
    DropZone,
    TransactionsDocumentsList,
    TransactionsDocumentSign,
  },
  props: {
    transactions: {
      type: Array,
      default: () => [],
    },
    deal: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    hoveredDocument: null,
    margin: null,
    invoiceFile: null,
    dropzoneOptions: {
      previewsContainer: false,
      autoProcessQueue: false,
      url: 'localhost',
    },
    paymentStatus: '',
  }),
  computed: {
    legals() {
      return this.$store.state.offers.legals;
    },
    offer() {
      return this.deal?.offer;
    },
    dealPrice() {
      return this.deal?.offer?.price || 0;
    },
    supplier() {
      return this.offer?.created_by || null;
    },
    buyer() {
      return this.deal?.accepted_by || null;
    },
    preparedTransactions() {
      return this.transactions.map((transaction, index) => {
        const first = index === 0;
        const last = index + 1 === this.transactions.length;

        return {
          ...transaction,
          first,
          last,
          buyerRole: first ? 'buyer' : 'intermediary',
          supplierRole: last ? 'supplier' : 'intermediary',
          buyerAvatarUrl: first ? this.buyer?.profile?.avatar_url : null,
          supplierAvatarUrl: last ? this.supplier?.profile?.avatar_url : null,
        };
      });
    },
  },
  methods: {
    getDocumentsTranslate(price, currency) {
      return this.$t('documentsTotal', { cost: `${price} ${getCurrencySymbol(currency)}` });
    },
    addInvoice(files) {
      const [file] = files;
      this.invoiceFile = file;
    },
    showZoneForDoc(doc, legalIndex) {
      if (legalIndex === 0) {
        return doc.type !== 'packing';
      }
      return true;
    },
    getRole(transaction) {
      if (transaction.buyerRole === 'buyer') {
        return 'buyer';
      }
      if (transaction.supplierRole === 'supplier') {
        return 'supplier';
      }
      return '';
    },
    getLegalMemberForOtherDocs(transaction) {
      if (transaction.supplierRole === 'supplier') return transaction.seller_legal;
      return transaction.buyer_legal;
    },
    getTransactionsLegal(transaction) {
      if (transaction.buyerRole === 'buyer') {
        return this.buyer;
      }
      if (transaction.supplierRole === 'supplier') {
        return this.supplier;
      }
      return null;
    },
    getDocumentsByLegalId(id) {
      const invoices = this.legals.find(legal => legal.id === id).invoices
        .filter(invoice => invoice.type !== 'delivery' && invoice.type !== 'clearance');
      return [
        ...this.legals.find(legal => legal.id === id).documents,
        ...invoices,
      ];
    },
    getReceiptsByLegalId(id) {
      const currentLegalInvoices = (this.legals.find(legal => legal.id === id)?.invoices || []);
      return [currentLegalInvoices.find(invoice => invoice.type === 'purchase')];
    },
    isCurrentInvoicePaid(id) {
      return this.getDocumentsByLegalId(id).find(invoice => invoice.type === 'purchase')?.invoice_paid || false;
    },
    isCurrentInvoiceSent(id) {
      return this.getDocumentsByLegalId(id).find(invoice => invoice.type === 'purchase')?.receipt_sent || false;
    },
    async sendPayment(legalId) {
      try {
        const formData = new FormData();
        formData.append('receipt', this.invoiceFile);
        formData.append('stage', 'purchase');
        formData.append('legal_id', legalId);
        await this.$store.dispatch('deal/setPaymentSent', { payload: formData, id: this.$route.params.id });
        this.paymentStatus = 'sent';
      } catch (e) {
        console.log(e);
      }
    },
    async paymentReceived(legalId) {
      try {
        await this.$store.dispatch('deal/setPaymentReceived', { id: this.$route.params.id, payload: { stage: 'purchase', legal_id: legalId } });
        this.paymentStatus = 'received';
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";
.tab-content {
  border: 1px solid $table-border-color;
  border-radius: 6px;
  transition: all .4s ease;
}
.tab-title {
  margin: 0 0.5rem;
}
.tab-title .nav-link {
  background-color: $table-border-color;
  .tab-title-text {
    border: 2px solid $headings-color;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 23px;
    height: 23px;
  }
  &.active .tab-title-text {
    border: 2px solid #ffffff;
  }
}
.dark-layout {
  .tab-title .nav-link {
    background-color: $theme-dark-widget-bg;
    .tab-title-text {
      border: 2px solid $theme-dark-headings-color;
    }
  }
  .tab-content {
    border:  1px solid $theme-dark-widget-bg;
  }
}
</style>
<style lang="scss" scoped>
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
</style>
