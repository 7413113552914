<template>
  <div>
    <div
      v-for="(item, i) in documents"
      :key="i"
      class="document-preview d-flex"
      :class="{ _active: hoveredDocument === i }"
      @mouseover="hoveredDocument = i"
      @mouseleave="hoveredDocument = null"
    >
      <div
        class="d-flex justify-content-between w-100 flex-wrap"
      >
        <div
          class="d-flex align-items-center mw-100 my-2"
        >
          <div class="mr-1">
            <feather-icon
              icon="FileTextIcon"
              size="36"
            />
          </div>
          <div class="mw-100">
            <div class="d-flex flex-column">
              <h6 class="document-preview-type">
                {{ getDocumentName(item) }}
              </h6>
              <span class="text-muted">
                {{ getDate(item) }}
              </span>
            </div>
          </div>
        </div>
        <div
          class="d-flex align-items-center justify-content-end document-preview-buttons"
        >
          <b-button
            target="_blank"
            class="mr-1"
            size="sm"
            @click="getPath(item)"
          >
            <feather-icon
              icon="DownloadIcon"
              size="18"
            />
          </b-button>
          <b-button
            size="sm"
            @click="getPath(item)"
          >
            <feather-icon
              icon="EyeIcon"
              size="18"
            />
          </b-button>
          <b-button
            size="sm"
            class="ml-1"
            @click="removeDoc(item)"
          >
            <feather-icon
              icon="XIcon"
              size="18"
            />
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';

export default {
  components: {
    BButton,
  },
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    isReceipt: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    hoveredDocument: null,
  }),
  methods: {
    async getPath(item) {
      let url = this.isReceipt ? item?.receipt_url : item?.url;
      if (!url) {
        const getUrl = await this.$store.dispatch('deal/getDocument', item.id);
        url = URL.createObjectURL(getUrl.data);
      }

      window.open(url, '_blank');
    },
    getDocumentName(item) {
      return this.isReceipt ? `${item?.document_name} receipt` : item?.document_name;
    },
    getDate(item) {
      const date = item?.invoice_creation_date || item?.created_at;
      if (date) {
        const newDate = new Date(date);
        const day = newDate.getDate() < 9 ? `0${newDate.getDate()}` : newDate.getDate();
        const month = (newDate.getMonth() + 1) <= 9 ? `0${(newDate.getMonth() + 1)}` : newDate.getMonth() + 1;
        return `${day}.${month}.${newDate.getFullYear()}`;
      }
      return '';
    },
    removeDoc(item) {
      this.$store.dispatch('deal/removeDocument', { documentId: item.id });
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";
.document-preview {
  cursor: pointer;
  overflow: hidden;
  &-buttons {
    opacity: 0;
    transition: all .4s ease;
  }
  &:hover {
    .document-preview-buttons {
      opacity: 1;
    }
  }
  &-type {
    font-weight: bold;
    text-transform: capitalize;
  }
}
</style>
