<template>
  <div>
    <vue-dropzone
      v-show="hideDropZone"
      id="payments-dropzone"
      ref="myVueDropzone"
      :use-custom-slot="true"
      :options="options"
      @vdropzone-file-added="addingFile"
    >
      <div class="d-flex flex-column align-items-center justify-content-center drop-zone">
        <feather-icon
          class="mb-2"
          icon="UploadIcon"
          size="24"
        />
        <slot name="description">
          <div class="d-flex flex-column align-items-center">
            <h6 class="my-1">
              Drop a check here or click to upload
            </h6>
            <span>
              PNG, JPG and GIF files are allowed
            </span>
          </div>
        </slot>
      </div>
    </vue-dropzone>
    <slot name="info-snackbar" />
    <transactions-documents-list
      v-if="showDocuments"
      :documents="files"
    />
    <slot
      name="footer"
      :reset="reset"
    />
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import TransactionsDocumentsList from '@/views/trading/trading-deals/transactions/TransactionsDocumentsList.vue';

export default {
  components: {
    TransactionsDocumentsList,
    vueDropzone: vue2Dropzone,
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    hideDropZone: {
      type: Boolean,
      default: true,
    },
    showDocuments: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    hoveredDocument: null,
    files: [],
  }),
  methods: {
    addingFile(file) {
      if (!this.isMultiple) {
        this.files = [file];
      } else {
        this.files.push(file);
      }
      this.$emit('add-file', this.files);
    },
    reset() {
      this.files = [];
      this.$emit('reset');
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";
.dropzone {
  transition: all .2s ease;
}
.dz-drag-hover {
  background-color: rgba(235, 233, 241, .3);
}
.drop-zone {
  border:  2px dashed $table-border-color;
  padding: 1rem;
  border-radius: 5px;
}
.dark-layout {
  .drop-zone {
    border:  2px dashed $theme-dark-widget-bg;
  }
}
</style>
