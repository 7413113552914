<template>
  <b-card
    no-body
    :class="{'open': visible}"
    class="collapse-icon"
  >
    <b-card-header
      :class="{'collapsed': !visible}"
      :aria-expanded="visible ? 'true' : 'false'"
      aria-controls="transactions-collapse"
      role="tab"
      data-toggle="collapse"
      @click="updateVisible(!visible)"
    >
      <h4 class="d-inline-block mr-1 mb-0">
        {{ title }}
      </h4>
    </b-card-header>
    <b-collapse
      id="transactions-collapse"
      v-model="visible"
      role="tabpanel"
      accordion
    >
      <hr class="divider m-0" />
      <b-card-body
        no-body
        class="p-2"
      >
        <div class="d-flex my-2">
          <b-avatar
            size="40"
            variant="light-primary"
            :src="avatarImage(supplier)"
          />
          <div class="ml-2">
            <h5>{{ companyName(supplier) }}</h5>
            <span class="text-muted">
              {{ $t('supplier') }}
            </span>
          </div>
        </div>
        <app-timeline>
          <template v-for="(tracking, index) in trackings">
            <app-timeline-item
              :key="index"
              :variant="tracking.selected ? 'success' : 'secondary'"
            >
              <div
                class="d-flex align-items-center tracking-item justify-content-between"
                :class="{'cursor-pointer': tracking.allow}"
              >
                <h6
                  class="mt-50 mb-1"
                  @click="tracking.allow ? setFocusOn(index) : null"
                >
                  {{ tracking.status }}
                </h6>
                <b-button
                  v-if="index === 1 && isSentButtonActive"
                  class="ml-1"
                  variant="primary"
                  @click="goodsSent"
                >
                  {{ $t('productsShipped') }}
                </b-button>
                <b-button
                  v-else-if="index === trackings.length - 1 && isReceivedBtnActive"
                  class="ml-1"
                  variant="primary"
                  @click="goodsReceived"
                >
                  {{ $t('productsReceived') }}
                </b-button>
                <div
                  v-else-if="focusOn === index"
                  class="ml-1"
                >
                  <b-button
                    variant="outline-secondary mr-1"
                    @click="prevDeliveryStatus(tracking)"
                  >
                    {{ $t('cancel') }}
                  </b-button>
                  <b-button
                    variant="primary"
                    @click="setDeliveryStatus(tracking.step)"
                  >
                    {{ $t('save') }}
                  </b-button>
                </div>
              </div>
              <span
                v-if="tracking.code"
                class="text-muted"
              >
                {{ tracking.code }}
              </span>
            </app-timeline-item>
          </template>
        </app-timeline>
        <div class="d-flex my-2">
          <b-avatar
            size="40"
            variant="light-primary"
            :src="avatarImage(buyer)"
          />
          <div class="ml-2">
            <h5>{{ companyName(buyer) }}</h5>
            <span class="text-muted">
              {{ $t('buyer') }}
            </span>
          </div>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BAvatar, BCard, BButton, BCardBody, BCardHeader, BCollapse,
} from 'bootstrap-vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import TradingNewCompany from '@/views/trading/TradingNewCompany.vue';
import { avatarText } from '@core/utils/filter.js';
import {
  COUNTRY_BUYER,
  COUNTRY_SUPPLIER, COUNTRY_TRANSIT,
  getTrackingIndex,
  prevTrackingStep,
  PRODUCTS_PACKING, PRODUCTS_RECEIVED,
  PRODUCTS_SHIPPED_WAREHOUSE,
} from '@/constants/deals.js';

export default {
  components: {
    TradingNewCompany,
    AppTimeline,
    AppTimelineItem,
    BAvatar,
    BCard,
    BButton,
    BCardBody,
    BCardHeader,
    BCollapse,
  },
  props: {
    title: {},
    userType: {
      type: String,
      default: '',
    },
    currentDeliveryStatus: {
      type: String,
      default: '',
    },
    buyer: {
      type: Object,
      default: () => {},
    },
    supplier: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      visible: true,
      focusOn: null,
    };
  },
  computed: {
    trackings() {
      const selectedIndex = getTrackingIndex(this.currentDeliveryStatus);

      return [
        {
          status: 'Products packaging',
          code: '',
          step: PRODUCTS_PACKING,
          allow: this.userType === 'admin',
        },
        {
          status: 'Products shipped from warehouse',
          code: '',
          step: PRODUCTS_SHIPPED_WAREHOUSE,
          allow: ['admin', 'supplier'].includes(this.userType),
        },
        {
          status: 'Country/Country code of supplier',
          code: '',
          step: COUNTRY_SUPPLIER,
          allow: this.userType === 'admin',
        },
        {
          status: 'Transit country/Country code',
          code: '',
          step: COUNTRY_TRANSIT,
          allow: this.userType === 'admin',
        },
        {
          status: 'Buyer\'s country/country code',
          code: '',
          step: COUNTRY_BUYER,
          allow: this.userType === 'admin',
        },
        {
          status: 'Products received',
          code: '',
          step: PRODUCTS_RECEIVED,
          allow: ['admin', 'buyer'].includes(this.userType),
        },
      ].map((state, index) => ({
        ...state,
        selected: index <= selectedIndex,
      }));
    },
    isReceivedBtnActive() {
      const { uploaded_documents } = this.$store.state.deal.deal;
      const buyerDocumentsSigned = uploaded_documents?.buyer && Object.values(uploaded_documents?.buyer).some(value => !value);
      return ['buyer', 'admin'].includes(this.userType) && !buyerDocumentsSigned && this.$store.state.deal.deal.status === 4;
    },
    isSentButtonActive() {
      const { uploaded_documents } = this.$store.state.deal.deal;
      const supplierDocumentsSigned = uploaded_documents?.supplier && Object.values(uploaded_documents?.supplier).some(value => !value);
      return ['supplier', 'admin'].includes(this.userType) && !supplierDocumentsSigned && this.$store.state.deal.deal.status === 3;
    },
  },
  created() {
    if (this.$store.state.deal.deal.status < 3) {
      this.updateVisible(false);
    }
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val;
    },
    async goodsSent() {
      await this.$store.dispatch('deal/setGoodsSent', this.$route.params.id);
    },
    async goodsReceived() {
      await this.$store.dispatch('deal/setGoodsReceived', this.$route.params.id);
    },
    async setDeliveryStatus(deliveryStatus) {
      await this.$store.dispatch('deal/setDeliveryStatus', { id: this.$route.params.id, deliveryStatus });
      this.unsetFocusOn();
    },
    async prevDeliveryStatus(tracking) {
      if (tracking.selected) {
        const prevDeliveryStatus = prevTrackingStep(tracking.step);
        await this.setDeliveryStatus(prevDeliveryStatus);
      }

      this.unsetFocusOn();
    },
    setFocusOn(index) {
      this.focusOn = index;
    },
    unsetFocusOn() {
      this.focusOn = null;
    },
    avatarText(user) {
      return avatarText(this.companyName(user));
    },
    avatarImage(user) {
      return user?.profile?.avatar_url || '';
    },
    companyName(user) {
      return user?.company_name || this.user?.company.company_name || '';
    },
  },
};
</script>

<style lang="scss" scoped>
.tracking-item {
  position: relative;
  top: -10px;
}
</style>
